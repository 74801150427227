// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
import $ from "jquery";

console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
import 'gems/turbo-rails/app/assets/javascripts/turbo'

//
import 'gems/activestorage/app/assets/javascripts/activestorage'
// ActiveStorage.start(); // is auto started

//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import "~/javascript/jquery.js" // defines global jQuery for the following Plugins
import "~/vendor/jquery-ui.min"
import "~/vendor/foundation-sites"
import "jqtree"
import 'gems/cocoon/app/assets/javascripts/cocoon'

import '~/vendor/jquery.swipebox'
import '~/vendor/summernote/summernote-lite'
import '~/vendor/summernote/summernote-ext-table'
import '~/vendor/summernote/summernote-image-title'
import '~/vendor/summernote/summernote-table-headers'
import '~/vendor/summernote/gallery_plugin'
import '~/vendor/summernote/table_zebra_plugin'
import '~/vendor/summernote/table_styles_plugin'
import "~/vendor/summernote/summernote_translations"

import "~/controllers"

function triggerJavascriptInit() {
    $(document).foundation();
}

$(document).on('turbo:load', function () {
    triggerJavascriptInit();
});
$(document).on('turbo:render', function () {
    triggerJavascriptInit();
});
