import {Controller} from "@hotwired/stimulus"

let sticky = 0;
const stickybg = 300;

export default class extends Controller {

    connect() {
        const header = this.element;
        sticky = header.offsetTop;
    }

    onWindowScroll() {
        const header = this.element;
        if (window.scrollY > sticky) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
        if (window.scrollY > stickybg) {
            header.classList.add("stickybg");
        } else {
            header.classList.remove("stickybg");
        }
    }
}
