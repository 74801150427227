import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = ['hiddenLink'];

    openReveal(event) {
        const $hiddenLink = $(this.hiddenLinkTargets);
        const $removeCocoonObject = $(this.element);
        const title = $removeCocoonObject.data('title');
        const content = $removeCocoonObject.data('content');
        const denyMsg = $removeCocoonObject.data('deny-msg');
        const confirmMsg = $removeCocoonObject.data('confirm-msg');
        let confirmAction = $removeCocoonObject.data('confirm-action');
        if (!confirmAction) {
            confirmAction = `<a class="button call-hidden-link close-reveal">${confirmMsg}</a>`;
        }

        const html = '<div class="reveal action-reveal">' +
            `<h3>${title}</h3>` +
            `<div class="content">${content}</div>` +
            '<div class="multi-buttons">' +
            `<button class="button close-reveal">${denyMsg}</button>` +
            '<div class="float-right">' +
            confirmAction +
            '</div>' +
            '</div>' +
            '</div>';

        $('body').append(html);
        const $reveal = $('body .reveal.action-reveal:last');
        $reveal.find('.close-reveal').on('click', function () {
            $reveal.foundation('close');
        });
        $reveal.find('.call-hidden-link').on('click', function () {
            if ($hiddenLink) {
                $hiddenLink.find('a').click();
            }
        });
        const foundationReveal = new Foundation.Reveal($reveal, {});
        foundationReveal.open();
        foundationReveal.$element.on('closed.zf.reveal', function (e) {
            foundationReveal.$element.foundation('_destroy');
            $reveal.remove();
        });
    }
}
