import $ from "jquery";

/**
 *
 * copyright 2023 Kon5 Communications.
 * email: h.fruechtel@kon5.eu
 * see: https://summernote.org/plugins
 *
 */
(function (factory) {
    // Browser globals
    factory(window.jQuery);
}(function ($) {
    /**
     * @class plugin.tableStylesPlugin
     *
     * table styles plugin
     */

    $.extend(true, $.summernote.lang, {
        "en-US": {
            tableStylesPlugin: {
                tooltip: "Table styles",
                toggleHeader: 'Header',
                toggleZebra: 'Zebra Style',
                width: 'Width'
            }
        }
    });

    $.extend($.summernote.options, {
        tableStylesPlugin: {
            icon: 'note-icon-summernote',
            widthStyles: {
                '25%': 'width_25',
                '50%': 'width_50',
                '75%': 'width_75',
                '100%': 'width_100'
            }
        }
    });

    // ###########################################################
    // ###########################################################
    // ###########################################################
    // create new summernote plugin
    // ###########################################################
    // ###########################################################
    // ###########################################################
    $.extend($.summernote.plugins, {
        /**
         *  @param {Object} context - context object has status of editor.
         */
        'tableStylesPlugin': function (context) {
            const self = this,
                // ui has renders to build ui elements
                // for e.g. you can create a button with 'ui.button'
                ui = $.summernote.ui,
                $note = context.layoutInfo.note,

                // contentEditable element
                $editor = context.layoutInfo.editor,
                $editable = context.layoutInfo.editable,
                $toolbar = context.layoutInfo.toolbar,

                // options holds the Options Information from Summernote and what we extended above.
                options = context.options,

                // lang holds the Language Information from Summernote and what we extended above.
                lang = options.langInfo;

            context.memo("button.tableStylesPlugin", function () {
                const buttonGroup = ui.buttonGroup([
                    ui.button({
                        className: "dropdown-toggle",
                        contents: ui.dropdownButtonContents(
                            ui.icon(options.tableStylesPlugin.icon),
                            options
                        ),
                        // set the container to show the tooltips
                        container: $editor,
                        tooltip: lang.tableStylesPlugin.tooltip,
                        data: {
                            toggle: "dropdown"
                        },
                        click: function () {
                            const $dropdown = $(this).next('.note-dropdown-menu');
                            self.updateTableWidthMenuItems($dropdown);
                            self.updateThMenuItem($dropdown);
                            self.updateZebraMenuItem($dropdown);
                        }
                    }),
                    ui.dropdownCheck({
                        className: "dropdown-table-styles-plugin",
                        checkClassName: options.icons.menuCheck,
                        items: self.generateListItems(),
                        callback: function ($dropdown) {
                            $dropdown.find("a.set-table-width").each(function () {
                                $(this).click(function (e) {
                                    self.updateTableWidthStyles(this);
                                    self.updateTableWidthMenuItems($dropdown);
                                    e.preventDefault();
                                });
                            });

                            $dropdown.find("a.toggle-th").each(function () {
                                $(this).click(function (e) {
                                    self.toggleTh();
                                    self.updateThMenuItem($dropdown);
                                    e.preventDefault();
                                });
                            });

                            $dropdown.find("a.toggle-zebra").each(function () {
                                $(this).click(function (e) {
                                    self.toggleZebra();
                                    self.updateZebraMenuItem($dropdown);
                                    e.preventDefault();
                                });
                            });
                        }
                    })
                ]);
                return buttonGroup.render();
            });

            // ###########################################################
            // ###########################################################
            // create the items for the dropdown
            // ###########################################################
            // ###########################################################
            self.generateListItems = function () {
                let list = '<ul class="summernote-plugin-dropdown">';

                list += self.getToggleThItem();
                list += '<hr style="margin: 5px 0px">';

                Object.keys(options.tableStylesPlugin.widthStyles).forEach(function (key) {
                    const value = options.tableStylesPlugin.widthStyles[key];
                    list += self.getWidthStyleItem(value, key);
                });

                list += '<hr style="margin: 5px 0px">';
                list += self.getToggleZebraItem();

                list += '</ul>'
                return list;
            };

            // ###########################################################
            // ###########################################################
            // width styles
            // ###########################################################
            // ###########################################################
            self.getWidthStyleItem = function (
                value,
                label
            ) {
                return '<li><a href="#" class="set-table-width" data-value="' + value + '">' +
                    '<i class="note-icon-menu-check" ></i>' +
                    " " + lang.tableStylesPlugin.width + ": " + label +
                    "</a></li>";
            };

            self.updateTableWidthStyles = function (chosenItem) {
                const rng = context.invoke("createRange", $editable);
                const dom = $.summernote.dom;
                if (rng.isCollapsed() && rng.isOnCell()) {
                    context.invoke("beforeCommand");
                    const $table = $(dom.ancestor(rng.commonAncestor(), dom.isTable));
                    const cssClass = $(chosenItem).data("value");
                    const widthStyles = options.tableStylesPlugin.widthStyles;
                    Object.keys(widthStyles).forEach(function (key) {
                        const value = widthStyles[key];
                        $table.removeClass(value);
                    });
                    $table.addClass(cssClass);
                    context.invoke("afterCommand");
                }
            };

            /* Makes sure the check marks are on the currently applied styles */
            self.updateTableWidthMenuItems = function ($dropdown) {
                const rng = context.invoke("createRange", $editable);
                const dom = $.summernote.dom;
                if (rng.isCollapsed() && rng.isOnCell()) {
                    const $table = $(dom.ancestor(rng.commonAncestor(), dom.isTable));
                    const $listItems = $dropdown.find("li a.set-table-width");
                    $listItems.each(function () {
                        const cssClass = $(this).data("value");
                        if ($table.hasClass(cssClass)) {
                            $(this).addClass("checked");
                        } else {
                            $(this).removeClass("checked");
                        }
                    });
                }
            };

            // ###########################################################
            // ###########################################################
            // toggle thead
            // ###########################################################
            // ###########################################################
            self.getToggleThItem = function () {
                return '<li><a href="#" class="toggle-th">' +
                    '<i class="note-icon-menu-check" ></i>' +
                    " " + lang.tableStylesPlugin.toggleHeader +
                    "</a></li>";
            };

            self.toggleTh = function () {
                const rng = context.invoke("createRange", $editable);
                const dom = $.summernote.dom;
                if (rng.isCollapsed() && rng.isOnCell()) {
                    context.invoke("beforeCommand");
                    const table = dom.ancestor(rng.commonAncestor(), dom.isTable);
                    context.triggerEvent('tableHeaders.toggleHeader');
                    context.invoke("afterCommand");
                }
            };

            self.updateThMenuItem = function ($dropdown) {
                const rng = context.invoke("createRange", $editable);
                const dom = $.summernote.dom;
                if (rng.isCollapsed() && rng.isOnCell()) {
                    const table = dom.ancestor(rng.commonAncestor(), dom.isTable);
                    const $toggleThItem = $dropdown.find("li a.toggle-th");
                    if ($(table).find('thead th').length > 0) {
                        $($toggleThItem).addClass('checked');
                    } else {
                        $($toggleThItem).removeClass('checked');
                    }
                }
            }


            // ###########################################################
            // ###########################################################
            // toggle zebra styling
            // ###########################################################
            // ###########################################################
            self.getToggleZebraItem = function () {
                return '<li><a href="#" class="toggle-zebra">' +
                    '<i class="note-icon-menu-check" ></i>' +
                    " " + lang.tableStylesPlugin.toggleZebra +
                    "</a></li>";
            };

            self.toggleZebra = function () {
                const rng = context.invoke("createRange", $editable);
                const dom = $.summernote.dom;
                if (rng.isCollapsed() && rng.isOnCell()) {
                    context.invoke("beforeCommand");
                    const table = dom.ancestor(rng.commonAncestor(), dom.isTable);
                    context.triggerEvent('tableZebra.toggleZebra');
                    context.invoke("afterCommand");
                }
            };

            self.updateZebraMenuItem = function ($dropdown) {
                const rng = context.invoke("createRange", $editable);
                const dom = $.summernote.dom;
                if (rng.isCollapsed() && rng.isOnCell()) {
                    const table = dom.ancestor(rng.commonAncestor(), dom.isTable);
                    const $toggleZebraItem = $dropdown.find("li a.toggle-zebra");
                    if ($(table).hasClass('table-zebra')) {
                        $($toggleZebraItem).addClass('checked');
                    } else {
                        $($toggleZebraItem).removeClass('checked');
                    }
                }
            }
        }
    });
}))
