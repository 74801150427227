import $ from "jquery";

/**
 *
 * copyright 2023 Kon5 Communications.
 * email: h.fruechtel@kon5.eu
 * see: https://summernote.org/plugins
 *
 */
(function (factory) {
    // Browser globals
    factory(window.jQuery);
}(function ($) {
    /**
     * @class plugin.galleryPlugin
     *
     * gallery plugin
     */
    $.extend($.summernote.options, {
        galleryPlugin: {
            icon: '<i class="fa fa-images"/>'
        }
    });

    $.extend(true,$.summernote.lang, {
        'en-US': { /* US English(Default Language) */
            galleryPlugin: {
                tooltip: 'Gallery',
                dialogTitle: 'Gallery Plugin',
                addButton: 'Add',
                closeButton: 'Close',
                deleteConfirmMsg: 'Do you really want to delete image "<image_name>"?'
            }
        },
        'de-DE': {
            galleryPlugin: {
                tooltip: 'Galerie',
                dialogTitle: 'Galerie Plugin',
                addButton: 'Hinzufügen',
                closeButton: 'Schließen',
                deleteConfirmMsg: 'Wollen Sie wirklich das Bild "<image_name>" löschen?'
            }
        }
    });

    // ###########################################################
    // ###########################################################
    // ###########################################################
    // create new summernote plugin
    // ###########################################################
    // ###########################################################
    // ###########################################################
    $.extend($.summernote.plugins, {
        /**
         *  @param {Object} context - context object has status of editor.
         */
        'galleryPlugin': function (context) {
            const self = this,
                // ui has renders to build ui elements
                // for e.g. you can create a button with 'ui.button'
                ui = $.summernote.ui,
                $note = context.layoutInfo.note,

                // contentEditable element
                $editor = context.layoutInfo.editor,
                $editable = context.layoutInfo.editable,
                $toolbar = context.layoutInfo.toolbar,

                // options holds the Options Information from Summernote and what we extended above.
                options = context.options,

                // lang holds the Language Information from Summernote and what we extended above.
                lang = options.langInfo;

            context.memo('button.galleryPlugin', function () {
                // Here we create a button
                const button = ui.button({
                    // icon for button
                    contents: options.galleryPlugin.icon,
                    // set the container to show the tooltips
                    container: $editor,
                    // tooltip for button
                    tooltip: lang.galleryPlugin.tooltip,
                    click: function (e) {
                        context.invoke('galleryPlugin.show');
                    }
                });
                return button.render();
            });

            // This section performs functions when the Plugin is first initialised.
            // Note, this is when the Plugin is loaded, not when the Plugin is used.
            this.initialize = function () {
                // This is how we can add a Modal Dialog to allow users to interact with the Plugin.

                // get the correct container for the plugin how it's attached to the document DOM.
                // Using the current latest development branch, you can now use $.summernote.interface;
                // to return which Summernote is being used to be able to adjust the modal layout to suit.
                // using this.options.id will return a generated timestamp when Summernote was initiliased
                // on page to allow using unique ID's.
                const $container = options.dialogsInBody ? $(document.body) : $editor;

                // Build the Body HTML of the Dialog.
                const body = '<div class="gallery-plugin-container"><div class="grid-container">' +
                    '<div class="grid-x grid-padding-x img-list-container"></div></div></div>';
                // Build the Footer HTML of the Dialog.
                const footer = '<div class="button-container">' +
                    '<span class="loading-spinner" style="display: none;">' +
                    '<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></span>' +
                    '<button href="#" class="button gallery-plugin-close-btn">' +
                    lang.galleryPlugin.closeButton + '</button>' +
                    '<button href="#" class="button gallery-plugin-add-btn">' +
                    lang.galleryPlugin.addButton + '</button>' +
                    '</div>';

                this.$dialog = ui.dialog({
                    // Set the title for the Dialog. Note: We don't need to build the markup for the Modal
                    // Header, we only need to set the Title.
                    title: lang.galleryPlugin.dialogTitle,
                    // Set the Body of the Dialog.
                    body: body,
                    // Set the Footer of the Dialog.
                    footer: footer
                    // This adds the Modal to the DOM.
                }).render().appendTo($container);
            }
            this.destroy = function () {
                ui.hideDialog(this.$dialog);
                this.$dialog.remove();
            };
            this.bindEnterKey = function ($input, $btn) {
                $input.on('keypress', function (event) {
                    if (event.keyCode === 13) $btn.trigger('click');
                });
            };
            this.bindLabels = function () {
                self.$dialog.find('.form-control:first').focus().select();
                self.$dialog.find('label').on('click', function () {
                    $(this).parent().find('.form-control:first').focus();
                });
            };
            this.show = function () {
                var $img = $($editable.data('target'));
                var editorInfo = {};
                this.showgalleryPluginDialog(editorInfo).then(function (editorInfo) {
                    ui.hideDialog(self.$dialog);
                    $note.val(context.invoke('code'));
                    $note.change();
                });
            };

            // ###########################################################
            // ###########################################################
            // plugin dialog
            // ###########################################################
            // ###########################################################
            let currentPage = 1;
            let isFetchLocked = false;
            let imgListContainer = null;
            let galleryPluginContainer = null;
            let lastPage = false;

            this.showgalleryPluginDialog = function (editorInfo) {
                return $.Deferred(function (deferred) {
                    currentPage = 1;
                    isFetchLocked = false;
                    imgListContainer = self.$dialog.find('.img-list-container');
                    imgListContainer.html('');
                    galleryPluginContainer = self.$dialog.find('.gallery-plugin-container')
                    scrollListener();
                    loadImages();
                    let $addBtn = self.$dialog.find('.gallery-plugin-add-btn');
                    let $closeBtn = self.$dialog.find('.gallery-plugin-close-btn');
                    ui.onDialogShown(self.$dialog, function () {
                        context.triggerEvent('dialog.shown');
                        $addBtn.click(function (e) {
                            e.preventDefault();
                            // add selected images to summernote editor
                            imgListContainer.find('.gallery-image.selected-image').each(function(index, img) {
                                context.invoke(
                                    'editor.pasteHTML',
                                    `<p><img src="${$(img).attr('src')}" alt="${$(img).attr('alt')}" ` +
                                    `data-id="${$(img).attr('data-id')}"/></p>`
                                );
                            });
                            deferred.resolve({});
                        });
                        $closeBtn.click(function (e) {
                            e.preventDefault();
                            deferred.resolve({});
                        });
                        self.bindEnterKey($addBtn);
                        self.bindEnterKey($closeBtn);
                        self.bindLabels();
                    });
                    ui.onDialogHidden(self.$dialog, function () {
                        $addBtn.off('click');
                        $closeBtn.off('click');
                        imgListContainer.html('');
                        if (deferred.state() === 'pending') deferred.reject();
                    });
                    ui.showDialog(self.$dialog);
                });
            };

            // ###########################################################
            // ###########################################################
            // load images
            // ###########################################################
            // ###########################################################
            function loadImages() {
                if (isFetchLocked) {
                    return;
                }

                startLoading();
                $.get('/summernote_images', {page: currentPage}, function(data) {
                    if (data && Array.isArray(data)) {
                        if (data.length === 0) {
                            lastPage = true;
                            galleryPluginContainer.off();
                            endLoading();
                        } else {
                            addImages(data);
                            loadMoreIfNoScrollBar();
                        }
                    }
                });
            }

            function startLoading () {
                isFetchLocked = true;
                self.$dialog.find('.note-modal-footer .loading-spinner').show();
            }

            function endLoading () {
                self.$dialog.find('.note-modal-footer .loading-spinner').hide();
                isFetchLocked = false;
                currentPage++;
            }

            function addImages(images) {
                images.forEach((image, index) => {
                    imgListContainer.append(
                        '<div class="cell large-3 medium-4 small-6 gallery-image-container"' +
                        `data-id="${image.id}" data-name="${image.filename}">` +
                        `<img class="gallery-image" src="${image.url}" alt="${image.filename}" ` +
                        `data-id="${image.id}"/>` +
                        `<i class="fa fa-trash delete-gallery-image"></i>` +
                        '</div>'
                    );
                });
                imgListContainer.find('.gallery-image').off().click(function(event) {
                    $(this).toggleClass('selected-image');
                });
                imgListContainer.find('.delete-gallery-image').off().click(function(event) {
                    const imgContainer = $(this).parent('.gallery-image-container');
                    const imgName = $(imgContainer).data('name');
                    const confirm_msg = lang.galleryPlugin.deleteConfirmMsg.replace(
                        '<image_name>', imgName);
                    if (confirm(confirm_msg)) {
                        deleteGalleryImage(imgContainer);
                    }
                });
                endLoading();
            }

            // ###########################################################
            // ###########################################################
            // delete image
            // ###########################################################
            // ###########################################################
            function deleteGalleryImage(imgContainer) {
                let data = new FormData;
                data.append('authenticity_token', $('meta[name=csrf-token]').attr('content'));
                const image_id = $(imgContainer).data('id');
                $.ajax({
                    data: data,
                    type: 'DELETE',
                    processData: false,
                    contentType: false,
                    url: '/summernote_images/' + image_id,
                    success: function(data) {
                        if (data.success) {
                            alert(data.msg);
                            $(imgContainer).remove();
                        } else if (data.msg) {
                            alert(data.msg);
                        } else {
                            alert('Can not delete image');
                        }
                    }
                })
            }

            // ###########################################################
            // ###########################################################
            // scrolling
            // ###########################################################
            // ###########################################################
            function scrollListener() {
                galleryPluginContainer.scroll(function() {
                    const isNearBottom = (galleryPluginContainer.scrollTop() +
                            galleryPluginContainer.height()) >= (imgListContainer.height() - 100);

                    if (isNearBottom) {
                        loadImages();
                    }
                });
            }

            // if the gallery modal has no scroll bar we load more pictures after time
            function loadMoreIfNoScrollBar() {
                setTimeout(function () {
                    if (galleryPluginContainer.height() === imgListContainer.height()) {
                        loadImages();
                    }
                }, 2000)
            }
        }
    });
}))
