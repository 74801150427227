import $ from "jquery";

/**
 *
 * copyright 2023 Kon5 Communications.
 * email: h.fruechtel@kon5.eu
 * see: https://summernote.org/plugins
 *
 */
(function (factory) {
    // Browser globals
    factory(window.jQuery);
}(function ($) {
    /**
     * @class plugin.tableZebraPlugin
     *
     * table zebra plugin
     */
    $.extend($.summernote.options, {
        tableZebraPlugin: {
            icon: '<i class="fa fa-brush"/>'
        }
    });

    $.extend(true, $.summernote.lang, {
        "en-US": {
            tableZebraPlugin: {
                tooltip: "Zebra Styling"
            }
        }
    });

    // ###########################################################
    // ###########################################################
    // ###########################################################
    // create new summernote plugin
    // ###########################################################
    // ###########################################################
    // ###########################################################
    $.extend($.summernote.plugins, {
        /**
         *  @param {Object} context - context object has status of editor.
         */
        'tableZebraPlugin': function (context) {
            const self = this,
                // ui has renders to build ui elements
                // for e.g. you can create a button with 'ui.button'
                ui = $.summernote.ui,
                $note = context.layoutInfo.note,

                // contentEditable element
                $editor = context.layoutInfo.editor,
                $editable = context.layoutInfo.editable,
                $toolbar = context.layoutInfo.toolbar,

                // options holds the Options Information from Summernote and what we extended above.
                options = context.options,

                // lang holds the Language Information from Summernote and what we extended above.
                lang = options.langInfo;

            context.memo('button.tableZebraPlugin', function () {
                const button = ui.button({
                    // icon for button
                    contents: options.tableZebraPlugin.icon,
                    // set the container to show the tooltips
                    container: $editor,
                    // tooltip for button
                    tooltip: lang.tableZebraPlugin.tooltip,
                    click: function (e) {
                        self.toggleTableZebra();
                    }
                });
                return button.render();
            });

            context.$note.on('summernote.tableZebra.toggleZebra', function () {
                self.toggleTableZebra();
            })

            this.toggleTableZebra = function () {
                const rng = context.invoke('createRange', $editable);
                const dom = $.summernote.dom;
                if (rng.isCollapsed() && rng.isOnCell()) {
                    context.invoke('beforeCommand');
                    const table = dom.ancestor(rng.commonAncestor(), dom.isTable)
                    const $table = $(table);
                    $table.toggleClass('table-zebra');
                    context.invoke('afterCommand');
                }
            };
        }
    });
}))
