import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {

    tabClicked() {
        const $tab = $(this.element);

        const classList = $tab.attr('class').split(/\s+/);
        let currTabContent = "";

        $.each(classList, function (index, value) {
            if (value.match(/^tab-atv/)) {
                currTabContent = "#" + value + "-content";
            }
        });

        $(".tab").not($tab).removeClass('active');
        $('.tab-content').not(currTabContent).slideUp();

        $tab.addClass('active');
        $(currTabContent).slideDown();
    }
}
