import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {

    connect() {
        const $editor = $(this.element);

        // send file to rails backend
        function sendFile($editor, file) {
            let data = new FormData;
            data.append('summernote_image[image]', file);
            data.append('authenticity_token', $('meta[name=csrf-token]').attr('content'))
            $.ajax({
                data: data,
                type: 'POST',
                processData: false,
                contentType: false,
                url: '/summernote_images',
                success: function (data) {
                    if (data.url) {
                        let img = document.createElement('IMG');
                        img.src = data.url;
                        img.alt = data.filename;
                        img.data = {id: data.id};
                        $editor.summernote('insertNode', img);
                    } else if (data.msg) {
                        alert(data.msg);
                    } else {
                        alert('Can not upload image');
                    }
                }
            })
        }

        // initialize the summernote editor
        $editor.summernote({
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['style', 'bold', 'italic', 'clear']],
                ['font', ['strikethrough', 'superscript', 'subscript']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['extensions', ['galleryPlugin']]
            ],
            minHeight: 200,
            callbacks: {
                onImageUpload: function (files) {
                    sendFile($editor, files[0])
                },
                onInit: function () {
                    $editor.next('.note-editor').find('.note-editable').addClass('frontend');
                }
            },
            imageTitle: {
                specificAltField: true,
            },
            popover: {
                image: [
                    ['custom', ['imageTitle']],
                    ['imagesize', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
                    ['float', ['floatLeft', 'floatRight', 'floatNone']],
                    ['remove', ['removeMedia']]
                ],
                link: [
                    ['link', ['linkDialogShow', 'unlink']]
                ],
                table: [
                    ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
                    ['delete', ['deleteRow', 'deleteCol']],
                    ['merge', ['jMerge']],
                    ['custom', ['tableStylesPlugin']],
                    ['deleteTable', ['deleteTable']]
                ],
                air: [
                    ['color', ['color']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['para', ['ul', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture']]
                ]
            },
            styleTags: [
                'p', 'h2', 'h3', 'h4'
            ]
        });
    }

    disconnect() {
        $(this.element).summernote('destroy');
    }
}
