import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = ['toggleHidden'];

    toggle(event) {
        $(this.toggleHiddenTargets).slideToggle();
        $(event.target).toggleClass('active');
    }
}
