import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  connect() {
    const $backToTopElm = $(this.element);
    $backToTopElm.hide();
  }

  onWindowScroll(event) {
    const $backToTopElm = $(this.element);
    if ($(event.target).scrollTop() > 100) {
      $backToTopElm.fadeIn();
    } else {
      $backToTopElm.fadeOut();
    }
  }

  scrollTop() {
    $('body,html').animate({
      scrollTop: 0
    }, 800);
  }
}
