(function (factory) {
    // Browser globals
    factory(window.jQuery);
}(function ($) {
    // custom summernote translations
    $.extend(true, $.summernote.lang, {
        'en-US': { /* US English(Default Language) */
            style: {
                h2: 'Headline',
                h3: 'Sub-headline',
                h4: 'List-Headline'
            }
        }
    });
}));
