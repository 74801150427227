import {Controller} from "@hotwired/stimulus"
import $ from "jquery";
import TomSelect from "tom-select";

export default class extends Controller {
    static targets = ['tomselectInput'];

    connect() {
        const $tomSelectInput = $(this.tomselectInputTargets);

        let settings = $tomSelectInput.data('tomselect');
        if (!settings) {
            settings = {}
        }
        // default, tomselect sets maxOptions to 50
        // we set the value to null to not use the default value and always show all options
        if (!settings['maxOptions']) {
            settings['maxOptions'] = null;
        }
        this.select = new TomSelect($tomSelectInput, settings);
    }

    disconnect() {
        if (this.select) {
            this.select.destroy();
        }
    }
}
