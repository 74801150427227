import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {

    connect() {
        $(this.element).sortable({
            axis: 'y',
            handle: '.handle',
            update: function () {
                let params = $(this).sortable('serialize');
                params += '&authenticity_token=' + $('meta[name=csrf-token]').attr('content');
                return $.post($(this).data('update-url'), params);
            }
        });
    }

    disconnect() {
        $(this.element).sortable('destroy');
    }
}
